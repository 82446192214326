import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import Trans from 'next-translate/Trans'
import dynamic from 'next/dynamic'

import styles from './../style.module.scss'
import { allRoutes } from '@shared-submodule/sm-constants/allRoutes'
import { useLazyQuery } from '@apollo/client'
import { isBottomReached, mapArticleData } from '@shared-submodule/utils'
import { HOME_PAGE_ARTICLE } from '@graphql/home/home.query'
import { articleLoader } from '@shared/libs/allLoader'
import useWindowSize from '@shared/hooks/windowSize'
import CustomLink from '@shared/components/customLink'
import GlobalEventsContext from '@shared/components/global-events/GlobalEventsContext'
import StaticBanner from '@shared/components/staticBanner'
// import { REACT_APP_ENV } from '@shared-submodule/sm-constants'
// import useOnMouseAndScroll from '@shared/hooks/useOnMouseAndScroll'
import { RightArrow } from '@shared/components/ctIcons'
import { ENUM_SERIES_CATEGORY_PAGES } from '@shared-submodule/sm-enum'
import { googleNewAdsData } from '@shared/components/adsData'
import { skyAddData } from '@shared/constants/skyAds'
import WordleWidget from '@shared/components/wordleWidget'
// import WPLWidget from '@shared/components/widgets/wplWidget'
// import CTMwebWidget from '@shared/components/widgets/ctWidget'

const LiveMatchCard = dynamic(() => import('@shared/components/liveMatchCard'))
const ArticleSmall = dynamic(() => import('@shared/components/article/articleSmall'), { loading: () => articleLoader(['s']) })
const GoogleAds = dynamic(() => import('@shared-submodule/sm-components/googleAds'), { ssr: false })
// const DownloadApp = dynamic(() => import('@shared/components/downloadApp'), { ssr: false })
const ArticleGrid = dynamic(() => import('@shared/components/article/articleGrid'), { loading: () => articleLoader(['g']) })
const ArticleMedium = dynamic(() => import('@shared/components/article/articleMedium'))
const AllWidget = dynamic(() => import('@shared/components/allWidget'), { ssr: false })
// const ATSAds = dynamic(() => import('@shared/components/atsAds'), { ssr: false })
// const Ads = dynamic(() => import('@shared/components/ads'), { ssr: false })

function HomePageArticle({ articles }) {
  const [article, setArticle] = useState(mapArticleData(articles) || [])
  const [loading, setLoading] = useState(false)
  const payload = useRef({ nLimit: 3, nSkip: 1 })
  const total = useRef(4)
  const isLoading = useRef(false)
  const [width] = useWindowSize()
  const { stateGlobalEvents } = useContext(GlobalEventsContext)
  // const { isLoaded } = useOnMouseAndScroll()
  // let banInd = -1

  // const { data, fetchMore } = useQuery(HOME_PAGE_ARTICLE, { variables: { input: payload.current } })

  // const latestArticle = data?.getHomePageArticle?.aResults?.length

  useEffect(() => {
    if (article) {
      isLoading.current = false
      setLoading(false)
      isBottomReached(article[article?.length - 1]?.sSlug, isReached)
    }
  }, [article])

  const [fetchMore, { data }] = useLazyQuery(HOME_PAGE_ARTICLE, { variables: { input: payload.current } })

  useEffect(() => {
    if (data?.getHomePageArticle) {
      handleArticleResponse(data?.getHomePageArticle)
    }
  }, [data])

  useEffect(() => {
    if (stateGlobalEvents?.homeArticle?.length) {
      const cloneArticls = article
      stateGlobalEvents?.homeArticle.forEach(match => {
        let currentMatch = cloneArticls.find(item => item?.oScore?.iMatchId === match?.iMatchId)
        if (currentMatch) {
          currentMatch = { ...currentMatch, oScore: { ...currentMatch?.oScore, ...match } }
          const index = cloneArticls.findIndex(s => s?.iSeriesId === currentMatch?.iSeriesId)
          cloneArticls[index] = currentMatch
        }
      })
      setArticle(cloneArticls)
    }
  }, [stateGlobalEvents])

  async function isReached(reach) {
    if (reach && !isLoading.current && total.current !== 0) {
      isLoading.current = true
      setLoading(true)
      const newSkip = (payload.current.nSkip += 1)
      handleMoreData(newSkip)
    }
  }

  function handleMoreData(number) {
    const newPayload = { nLimit: 3, nSkip: number }
    fetchMore({ variables: { input: newPayload } })
  }

  function handleArticleResponse(data) {
    total.current = data?.aResults?.length
    setArticle([...article, ...mapArticleData(data?.aResults)])
  }

  function getCustomURL(aCustomSeo = []) {
    const URLs = {}
    aCustomSeo?.forEach(e => {
      URLs[ENUM_SERIES_CATEGORY_PAGES[e.eSubType]] = `/${e?.sSlug}`
    })
    return URLs
  }
  return (
    <>
      {article?.map((cat, ind) => {
        const HeadingTag = ind === 0 ? 'h1' : ind > 2 ? 'h4' : 'h2'
        const rewriteURLs = cat?.eType === 'as' ? getCustomURL(cat?.aScore?.[0]?.oSeriesSeos?.oSeo) : {}
        // if (ind % 2 === 0) {
        //   ++banInd
        //   if ((width < 768 && banInd > 5) || (width > 767 && banInd > 2)) {
        //     banInd = 0
        //   }
        // }
        return (
          <React.Fragment key={`${cat.sSlug}${ind}`}>
            {ind % 2 === 0 && ind !== 0 && <StaticBanner isTop />} {/* Add static banner after every two category articles */}
            {/* {width < 768 && ind % 3 === 0 && <StaticBannerSecond adType="secondAdRect_Left" link="home_left_banner" />} */}
            {/* Add static banner after every two category articles */}
            {/* {width < 768 && ind % 5 === 0 && ind !== 0 && <StaticBannerSecond adType="secondAd_Rect" link="home_right_banner" />} */}
            {/* {ind === 1 && width < 767 && <WPLWidget />} */}
            {/* Add static banner after every two category articles */}
            {ind % 2 === 0 && ind !== 0 && width > 768 && (
              <GoogleAds
                addId={googleNewAdsData?.homePage?.hotStory?.web?.addId}
                divId={`div-gpt-ad-17280322948${ind}5-0`}
                dimension={googleNewAdsData?.homePage?.hotStory?.web?.dimension}
                slotId={googleNewAdsData?.homePage?.hotStory?.web?.slotId}
                className={'mb-3'}
              />
            )}
            {ind % 2 === 0 && ind !== 0 && width < 768 && (
              <GoogleAds
                addId={skyAddData?.addRight?.addId}
                divId={`div-gpt-ad-17277883607${ind}9-0`}
                dimension={skyAddData?.addRight?.dimension}
                slotId={skyAddData?.addRight?.slotId}
                className={'mb-3'}
              />
            )}
            <section id={cat.sSlug} className={`${styles.homeArticles}`}>
              <div className={`common-box ${styles.boxBtmPad}`}>
                <HeadingTag className={`${styles.h4MarginBtm} h4`}>
                  {cat?.sSlug ? (
                    <CustomLink href={cat?.eType === 's' ? `/${cat.sSlug}` : allRoutes.seriesHome(cat.sSlug)} prefetch={false}>
                      <a className="rounded-pill position-relative d-inline-block">
                        <span className="d-block text-nowrap overflow-hidden">{cat.sName}</span>
                      </a>
                    </CustomLink>
                  ) : (
                    cat.sName
                  )}
                </HeadingTag>
                {cat?.eType === 'as' && (
                  <div className={`${styles.seriesInfo}`}>
                    {/* <p className="mb-2">{cat?.aScore?.[0]?.oSeries?.sTitle}</p> */}
                    <div className={`${styles.btnList} text-nowrap scroll-list d-flex`}>
                      <nav className="font-semi d-flex">
                        {rewriteURLs?.fixtures && (
                          <CustomLink href={rewriteURLs?.fixtures} prefetch={false}>
                            <a>
                              <Trans i18nKey="common:Fixtures" />
                            </a>
                          </CustomLink>
                        )}
                        {cat?.aScore?.[0]?.oSeries?.nTotalTeams > 2 && rewriteURLs?.standings && (
                          <CustomLink href={rewriteURLs?.standings} prefetch={false}>
                            <a>
                              <Trans i18nKey="common:Standings" />
                            </a>
                          </CustomLink>
                        )}
                        {rewriteURLs?.stats && (
                          <CustomLink href={rewriteURLs?.stats} prefetch={false}>
                            <a>
                              <Trans i18nKey="common:Stats" />
                            </a>
                          </CustomLink>
                        )}
                        {rewriteURLs?.squads && (
                          <CustomLink href={rewriteURLs?.squads} prefetch={false}>
                            <a>
                              <Trans i18nKey="common:Squads" />
                            </a>
                          </CustomLink>
                        )}
                        {/* <CustomLink href={allRoutes.seriesFantasyTips(`/${cat.sSlug}/`)} prefetch={false}>
                          <a>
                            <Trans i18nKey="common:FantasyTips" />
                          </a>
                        </CustomLink> */}
                      </nav>
                    </div>
                    {cat?.bScoreCard && cat?.aScore?.length !== 0 && <LiveMatchCard data={cat} />}
                  </div>
                )}
                {cat.aArticle.map((ar, ai) => {
                  if (ar.sType === 'nBig') {
                    return <ArticleMedium data={ar} key={`${ar._id}${ai}`} index={ind} isFirstImg={ind === 0 && ai === 0} />
                  } else if (ar.sType === 'nGrid') {
                    return (
                      <Row className={`row-4 flex-nowrap article-grid-list scroll-list ${styles.articleGridList}`} key={ar.sType}>
                        {ar.mappedArticle.map((g, gi) => {
                          return (
                            <Col key={`${g._id}${gi}`} md={6} sm={5}>
                              <ArticleGrid data={g} index={ind} isFirstImg={ind === 0 && gi === 0} />
                            </Col>
                          )
                        })}
                      </Row>
                    )
                  } else if (ar.sType === 'nMedGrid') {
                    return (
                      <Row className="row-4 scroll-list" key={ar.sType}>
                        <Col key={ar?.mappedArticle?.[0]?._id} xl={6}>
                          <ArticleGrid data={ar?.mappedArticle?.[0]} index={ind} isFirstImg={ind === 0 && ai === 0} />
                        </Col>
                        <Col xl={6}>
                          {ar.mappedArticle.map((g, i) => {
                            if (i > 0) {
                              return (
                                <ArticleSmall data={g} key={`${g._id}${i}`} index={ind} isFirstImg={ind === 0 && ai === 0 && i === 0} />
                              )
                            } else {
                              return null
                            }
                          })}
                        </Col>
                      </Row>
                    )
                  } else if (ar.sType === 'nMed') {
                    return <ArticleMedium data={ar} key={`${ar._id}${ai}`} index={ind} isFirstImg={ind === 0 && ai === 0} />
                  }
                  return null
                })}
                {cat?.sSlug && (
                  <div className="text-center">
                    <CustomLink href={cat?.eType === 's' ? `/${cat?.sSlug}` : allRoutes.seriesHome(cat?.sSlug)} prefetch={false}>
                      <a className="theme-btn btn btn-primary">
                        <Trans i18nKey="common:ViewAll" />
                        <RightArrow />
                      </a>
                    </CustomLink>
                  </div>
                )}
              </div>
            </section>
            {/* {ind === 1 && width < 767 && <CTMwebWidget />} */}
            {/* {
              ind % 2 === 0 && isLoaded && <ATSAds />
            } */}
            {/* {
              ind === 0 && width < 991 && isLoaded && <DownloadApp isMob />
            } */}
            {ind === 1 && width < 991 && <AllWidget show={width < 991} index={0} />}
            {ind === 1 && width < 767 && <WordleWidget />}
            <>{/* <WTC2023 /> */}</>
            {/* {((ind + 1) % 2 === 0) && isLoaded && (width < 991) && <AllWidget show={width < 991} index={((ind + 1) / 2) - 1} />} */}
          </React.Fragment>
        )
      })}
      {loading && articleLoader(['g', 's'])}
    </>
  )
}
HomePageArticle.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object)
}
export default HomePageArticle
