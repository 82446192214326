import React from 'react'
import PropTypes from 'prop-types'
import Error from '@shared/components/error'
import HomePageContent from '@shared/components/homePageComponents/homePageContent'
import HomePageArticle from '@shared/components/homePageComponents/homePageArticle'
import { endTime, execTime, startTime } from '@shared/libs/metrics'

function Home({ articles, miniScorecard, fantasyTips, seoData }) {
  return (
    <>
      <HomePageContent seoData={seoData} articles={articles} miniScorecard={miniScorecard} fantasyTips={fantasyTips}>
        <HomePageArticle articles={articles} />
      </HomePageContent>
    </>
  )
}

Home.propTypes = {
  articles: PropTypes.array,
  miniScorecard: PropTypes.array,
  fantasyTips: PropTypes.object,
  seoData: PropTypes.object
}

export default Error(Home)

export async function getServerSideProps({ res, query, resolvedUrl }) {
  console.log(process.env)
  try {
    if (query?.p) {
      try {
        const getRedirectionURL = (await import('@shared/libs/homePage')).redirectToArticleWhenQueryHaveID
        const { returnObj } = await getRedirectionURL(query?.p)
        return returnObj
      } catch (e) {
        return { notFound: true }
      }
    } else {
      res.setHeader('Cache-Control', 'max-age=3600, stale-while-revalidate=300, stale-if-error=600')
      const homePageFunction = (await import('@shared/libs/homePage')).getHomePageData
      startTime('homePageFunction')
      const data = await homePageFunction('a')
      const timeTaken = endTime('homePageFunction')
      data.perf = timeTaken
      data.totalServerTime = execTime()
      return {
        props: data
      }
    }
  } catch (e) {
    res.setHeader('Cache-Control', 'no-cache')
    const handleApiError = (await import('@shared-submodule/utils')).handleApiError
    const status = handleApiError(e, resolvedUrl)
    return status
  }
}
