import React from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-bootstrap'
import dynamic from 'next/dynamic'

import styles from './style.module.scss'
import useWindowSize from '@shared/hooks/windowSize'
import StaticBanner from '@shared/components/staticBanner'
import useOnMouseAndScroll from '@shared/hooks/useOnMouseAndScroll'
// import SBSpecial from '@shared/components/sbSpecial'
import { googleAddData } from '@shared-submodule/sm-constants/googleAdsData'
import { skyAddData } from '@shared/constants/skyAds'
import { googleNewAdsData } from '@shared/components/adsData'
import WordleWidget from '@shared/components/wordleWidget'

const GoogleAds = dynamic(() => import('@shared-submodule/sm-components/googleAds'), { ssr: false })
const AllWidget = dynamic(() => import('@shared/components/allWidget'), { ssr: false })
const WTC2023 = dynamic(() => import('@shared/components/widgets/wtc2023'), { ssr: false })
// const DownloadApp = dynamic(() => import('@shared/components/downloadApp'), { ssr: false })
const ScorecardSlider = dynamic(() => import('@shared/components/scorecardSlider'))
const Layout = dynamic(() => import('@shared/components/layout'))
const ATSAds = dynamic(() => import('@shared/components/atsAds'), { ssr: false })
// const PromotionSmall = dynamic(() => import('@shared/components/adsPromotion/promotionSmall'))
function HomePageContent({ articles, children, miniScorecard, fantasyTips, seoData }) {
  const { isLoaded } = useOnMouseAndScroll()
  const [width] = useWindowSize()
  // const [activeTab, setActiveTab] = useState('ar')

  function getMetaDetail() {
    return {
      oSeo: {
        sTitle: seoData?.sTitle,
        sDescription: seoData?.sDescription,
        aKeywords: seoData?.aKeywords
      }
    }
  }

  return (
    <Layout data={getMetaDetail()}>
      <ScorecardSlider isSeriesTitle data={miniScorecard} />
      <ATSAds />
      {/* <Container><StaticBanner isTop/></Container> */}
      <div className="pt-0">
        <Container className={styles.homeContainer}>
          <div className="w-full d-none d-md-flex justify-content-center ">
            <GoogleAds
              addId={googleAddData?.addTop?.addId}
              divId={googleAddData?.addTop?.divId}
              dimension={googleAddData?.addTop?.dimension}
              slotId={googleAddData?.addTop?.slotId}
              className={'mt-0 mb-3 '}
            />
          </div>
          <div className="w-full d-flex d-md-none justify-content-center ">
            <GoogleAds
              addId={googleAddData?.mobSm?.addId}
              divId={googleAddData?.mobSm?.divId}
              dimension={googleAddData?.mobSm?.dimension}
              slotId={googleAddData?.mobSm?.slotId}
              className={'mb-3'}
            />
          </div>
          {/* {width < 990 && <SBSpecial />} */}
          <Row>
            <Col lg={3} className={`${styles.commonSidebar} order-1 order-lg-0 common-sidebar bt-width d-none d-lg-block`}>
              {/* <FantasyTipsWidget fantasyTips={fantasyTips} isDesk /> */}
              {/* <SBSpecial /> */}
              {/* <HILWidget /> */}
              {/* <GoogleAds
                addId={googleAddData?.addLeft?.addId}
                divId={googleAddData?.addLeft?.divId}
                dimension={googleAddData?.addLeft?.dimension}
                slotId={googleAddData?.addLeft?.slotId}
                className={'mt-0 mb-4'}
              /> */}
              <>
                {/* <PromotionSmall /> */}
                {width > 990 && (
                  <>
                    <AllWidget show={true} position="l" />
                    <WordleWidget className='mt-0' />
                    {/* <StaticBannerSecond adType="secondAdRect_Left" link="home_left_banner" /> */}
                  </>
                )}
                <GoogleAds
                  addId={googleNewAdsData?.homePage?.left?.addId}
                  divId={googleNewAdsData?.homePage?.left?.divId}
                  dimension={googleNewAdsData?.homePage?.left?.dimension}
                  slotId={googleNewAdsData?.homePage?.left?.slotId}
                />
              </>
            </Col>
            <Col lg={6} className={styles.centerContent}>
              {/* {width > 990 && <DownloadApp isDesk />} */}
              {/* {width < 990 && <ChessWidgetMweb />} */}
              {children}
              {width > 1000 && articles?.length > 0 && (
                <GoogleAds
                  addId={skyAddData?.addBottom?.addId}
                  divId={skyAddData?.addBottom?.divId}
                  dimension={skyAddData?.addBottom?.dimension}
                  slotId={skyAddData?.addBottom?.slotId}
                  className={'my-3'}
                />
              )}
              {width < 720 && (
                <GoogleAds
                  addId={skyAddData?.addRight?.addId}
                  divId={skyAddData?.addRight?.divId}
                  dimension={skyAddData?.addRight?.dimension}
                  slotId={skyAddData?.addRight?.slotId}
                  className={'my-3'}
                />
              )}
            </Col>
            {width > 990 && (
              <Col lg={3} className={`${styles.commonSidebar} common-sidebar bt-width d-none d-lg-block`}>
                <>
                  <WTC2023 />
                  {/* <WPLWidget/> */}
                  <GoogleAds
                    addId={skyAddData?.addRight?.addId}
                    divId={skyAddData?.addRight?.divId}
                    dimension={skyAddData?.addRight?.dimension}
                    slotId={skyAddData?.addRight?.slotId}
                    className={'my-2'}
                  />
                  {/* <DemoADD1/> */}
                  <ATSAds />

                  {
                    <>
                      {/* <LiveStream/> */}
                      <AllWidget show={true} position="r" />
                      <GoogleAds
                        addId={googleAddData?.addRight?.addId}
                        divId={googleAddData?.addRight?.divId}
                        dimension={googleAddData?.addRight?.dimension}
                        slotId={googleAddData?.addRight?.slotId}
                        className={'mt-0'}
                      />
                      {isLoaded && <StaticBanner isLeft />}
                    </>
                    // )
                  }
                  {/* <Ads
                      id="div-ad-gpt-138639789-1660055716-Desktop_HP_RightBTF-0"
                      adIdDesktop="oneturf2022_Desktop_HP_RightBTF_300x600"
                      dimensionDesktop={[300, 600]}
                      className="sticky-ads"
                    /> */}
                </>
              </Col>
            )}
          </Row>
          <a href="https://o2o.to/i/0MacsN" target="_blank" rel="noopener noreferrer nofollow">
            <div className={`d-md-flex d-none justify-content-center align-items-center ${styles.downloadBanner}`}></div>
          </a>
        </Container>
      </div>
    </Layout>
  )
}
HomePageContent.propTypes = {
  children: PropTypes.object,
  articles: PropTypes.array,
  miniScorecard: PropTypes.array,
  fantasyTips: PropTypes.object,
  seoData: PropTypes.object
}
export default HomePageContent
