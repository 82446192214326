export const miniScorecard = `
dStartDate
iBattingTeamId
iMatchId
nPriority
nOrder
nLatestInningNumber
oSeries {
  _id
  sTitle
  sSrtTitle
}
oTeamScoreA {
  oTeam {
    _id
    sAbbr
    oImg {
      sUrl
    }
    sTitle
  }
  sScoresFull
  sScores
  sOvers
  iTeamId
}
oTeamScoreB {
  oTeam {
    _id
    sAbbr
    oImg {
      sUrl
    }
    sTitle
  }
  sScoresFull
  sScores
  sOvers
  iTeamId
}
sStatusNote
sTitle
sSubtitle
sStatusStr
oStatus {
  sValue
  sText
}
sLiveGameStatusStr
oLiveGameStatus {
  sText
  sValue
}
oSeo {
  sSlug
  sDescription
  sTitle
}
iWinnerId
oMatchSeos {
  oSeo {
    eSubType
    sSlug
  }
}
`
