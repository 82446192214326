import React from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import { adsConfig } from '@shared/libs/addUtils'
import useWindowSize from '@shared/hooks/windowSize'

const MyImage = dynamic(() => import('@shared/components/myImage'))

const StaticBanner = ({ isLeft = false, isTop = false, isMiddle = false, isFirst = false, index = 0 }) => {
  const [width] = useWindowSize()

  // program to get a random item from an array
  const getRandomItem = (arr) => {
    const randomIndex = Math.floor(Math.random() * arr.length)
    const item = arr[randomIndex]
    return item
  }

  const data = isTop ? adsConfig.top : width < 767 ? adsConfig.middleBarMob[index] : isLeft ? getRandomItem(adsConfig.leftSidebar) : isMiddle ? adsConfig.middleBarDesk[index] : getRandomItem(adsConfig.rightSidebar)
  return (
    <div className={`downloadApp staticBanner d-none mb-3 mb-md-3 p-0 position-relative overflow-hidden ${isTop && 'banHgtFrst'} ${isFirst ? '' : 'isNotFirst'}`}>
        <a href={data?.link} target="_blank" rel='noopener noreferrer nofollow'>
          <MyImage
            // loading={isTop ? 'eager' : 'lazy'}
            priority={isTop}
            placeholder="blur"
            src={data?.url}
            alt="SBZ app"
            layout="responsive"
          />
        </a>
    </div >
  )
}
StaticBanner.propTypes = {
  isLeft: PropTypes.bool,
  isTop: PropTypes.bool,
  isMiddle: PropTypes.bool,
  isFirst: PropTypes.bool,
  index: PropTypes.number
}

export default React.memo(StaticBanner)
